<template>
  <div v-if="isPermission">
    <CCol md="12" lg="12">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <div class="col-md-10">
              <h2 class="font-weight-normal">
                <span class="h3 font-weight-normal">
                  <strong class="font-weight-normal">{{ $t('productName') }} :</strong> {{ name }}
                </span>
              </h2>
            </div>
            <div class="col-md-2 text-right text-dark">
              <CButton id="stockCardDetailBackButton" block color="light" class="align-items-center"  @click="goback()">
                <span><i class="fi fi-rr-arrow-left mr-2" style="position: relative; top: 2px;"></i></span>{{ $t('back') }}
              </CButton>
            </div>
          </div>
          <hr />
          <CRow>
            <CCol sm="12" lg="12">
              <CDataTable
                style="white-space:nowrap;"
                :items="items"
                :fields="fields"
                :loading="loading"
                hover
                border
                id="stockCardDetailTable"
              >
              <template #no-items-view>
                <div class="text-center my-5">
                  <h4 style="color: #ced2d8;">
                    {{ $t('noItem') }}
                  </h4>
                </div>
              </template>
              </CDataTable>
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="getStockCardReport">
          </pagination>
        </CCardBody>
      </CCard>
    </CCol>
  </div>
  <div v-else>
    <access-data></access-data>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/service'
import pos from '@/services/local'
import Pagination from '@/containers/Pagination'
import util from '@/util/util'
import permis from '@/util/permission'

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      data: [],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      loadingButton: true,
      pluCode: '',
      name: '',
      category: '',
      onhandQty: '',
      lastupdate: '',
    }
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date']),
    isPermission() {
      let path = '/inventory/stockcard'
      return permis.findPermissionRead('inventory', path)
    },
    isExport() {
      let path = '/inventory/stockcard'
      return permis.findPermissionExport('inventory', path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        { key: 'date', label: this.$i18n.t('date'), _classes: 'text-dark font-weight-normal', },
        {
          key: 'documentNo',
          label: this.$i18n.t('documentNo'),
          _style: 'width:35%',
          _classes: 'text-dark font-weight-normal',
        },
        /*  { key: "name", label: "ชื่อสินค้า" },
        { key: "unit", label: "หน่วยบรรจุ", _classes: "text-center" }, */
        {
          key: 'transactionType',
          label: this.$i18n.t('transactionType'),
          _classes: 'text-dark font-weight-normal',
        },
        {
          key: 'quantity',
          label: this.$i18n.t('quantity'),
          _classes: 'text-right font-weight-normal',
          _style: 'width:12%',
        },
        /*  { key: "afterQty", label: "คงเหลือ", _classes: "text-right" }, */
        {
          key: 'updatedBy',
          label: this.$i18n.t('updatedBy'),
          _classes: 'text-right text-dark font-weight-normal',
          _style: 'width:15%',
        },
      ]
    },
    items() {
      const data = this.data || []
      let detail = []
      let transactionType = {}
      let afterQty = {}
      
      
      let cellClasses = {}
      
      let quantity = ''

      for (let i = 0; i < data.length; i++) {
        const item = data[i]
        let classes = ''
        if (item.deleted_at != undefined) {
          classes = 'text-danger'
        } else {
          classes = 'text-dark'
        }

        if (item.transactionType === 1) {
          transactionType = this.$i18n.t('transactionType1')
        } else if (item.transactionType === 2) {
          transactionType = this.$i18n.t('transactionType2')
        } else if (item.transactionType === 3) {
          transactionType = this.$i18n.t('transactionType3')
        } else if (item.transactionType === 4) {
          transactionType = this.$i18n.t('transactionType4')
        } else if (item.transactionType === 5) {
          transactionType = this.$i18n.t('transactionType5')
        } else if (item.transactionType === 6) {
          transactionType = this.$i18n.t('transactionType6')
        } else if (item.transactionType === 7) {
          transactionType = this.$i18n.t('transactionType7')
        } else if (item.transactionType === 8) {
          transactionType = this.$i18n.t('transactionType8')
        } else if (item.transactionType === 9) {
          transactionType = this.$i18n.t('transactionType9')
        } else if (item.transactionType === 10) {
          transactionType = this.$i18n.t('transactionType10')
        } else if (item.transactionType === 11) {
          transactionType = this.$i18n.t('transactionType11')
        } else if (item.transactionType === 12) {
          transactionType = this.$i18n.t('transactionType12')
        } else if (item.transactionType === 13) {
          transactionType = this.$i18n.t('transactionType13')
        } else if (item.transactionType === 14) {
          transactionType = this.$i18n.t('transactionType14')
        } else if (item.transactionType === 15) {
          transactionType = this.$i18n.t('transactionType15')
        } else if (item.transactionType === 16) {
          transactionType = this.$i18n.t('transactionType16')
        } else if (item.transactionType === 18) {
          transactionType = this.$i18n.t('transactionType18')
        } else if (item.transactionType === 19) {
          transactionType = this.$i18n.t('transactionType19')
        } else {
          transactionType = this.$i18n.t('transactionOther')
        }

        if (item.afterQty === undefined) {
          afterQty = '-'
        } else {
          afterQty = item.afterQty
        }

        const updatedBy = item.updated_by?.username || item.updated_by?.name || item.created_by?.username || '-';      

        const unit = item.SKUUnit || '-'
        const name = item.SKUName || '-'
        
        if (item.quantity < 0 || item.transactionType === 4) {
          cellClasses = {
            quantity: 'text-danger text-right',
          }
        } else {
          if (item.deleted_at != undefined) {
            cellClasses = {
              quantity: 'text-danger text-right',
            }
          } else {
            cellClasses = {
              quantity: 'text-dark text-right',
            }
          }
        }

        if (item.transactionType === 4) {
          if (item.quantity > 0) {
            let qty = util.convertCurrency(Number(item.quantity))
            quantity = '-' + qty
          } else {
            quantity = util.convertCurrency(Number(item.quantity))
          }
        } else {
          quantity = util.convertCurrency(Number(item.quantity))
        }
        detail.push({
          date: moment(String(item.created_at)).format('DD/MM/YYYY HH:mm:ss'),
          documentNo: data[i].documentNo,
          name: name,
          unit: unit,
          transactionType: transactionType,
          quantity: quantity,
          afterQty: afterQty,
          updatedBy: updatedBy,
          _cellClasses: cellClasses,
          _classes: classes,
        })
      }
      return detail
    },
    sortdata() {
      return this.$route.query.sort || 'SKUName'
    },
    page() {
      return this.$route.query.page || 1
    },
    searchBy() {
      return this.$route.query.searchBy || ''
    },
    keyword() {
      return this.$route.query.keyword || ''
    },
    queryURL() {
      return (
        '?sort=' +
        this.sortdata +
        '&page=' +
        this.page +
        '&searchBy=' +
        this.searchBy +
        '&keyword=' +
        this.keyword
      )
    },
  },
  created() {
    this.getStockCardReport()
    this.getProductSKUStock()
  },
  methods: {
    ...util,
    goback() {
      this.$router.push('/inventory/stockcard' + this.queryURL)
    },
    getStockCardReport(page = 1) {
      this.loadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`
      const plan = this.users.currentPlan
      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        productSKUObjectId: this.$route.params.productSKUObjectId,
        shopObjectId: this.$route.params.shopObjectId,
        plan: plan,
        startAt: startAt,
        endAt: endAt,
        page: page,
        limit: 50,
      }
      const headers = {
        shopObjectId: this.shopObjectId,
      }
      this.loading = true
      axios({
        url: '/inventory/v1.0/' + uid + '/getstockcard',
        headers: headers,
        method: 'GET',
        params: params,
      })
        .then((res) => {
          this.data = res.data.data
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
          } else {
            this.meta_data.items = res.data.paginate.items
          }
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.meta_data.itemCount = res.data.paginate.itemCount
          this.meta_data.limit = res.data.paginate.perPage
          this.loading = false
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getProductSKUStock() {
      this.loadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`
      const headers = {
        shopObjectId: this.shopObjectId,
      }
      this.loading = true
      pos({
        url:
          '/api/v1.0/' +
          uid +
          '/getstockbysku/' +
          this.$route.params.productSKUObjectId,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          if (res.data.data != undefined) {
            this.name = res.data.data.SKUName
            this.pluCode = res.data.data.SKUName
            this.category = res.data.data.categoryName
            this.lastupdate = moment(res.data.data.updated_at).format(
              'DD MMMM YYYY HH:mm:ss'
            )
            if (res.data.data.onhandQty != undefined) {
              this.onhandQty = util.convertCurrency(res.data.data.onhandQty)
            } else {
              this.onhandQty = 'non-stock products'
            }
          } else {
            this.onhandQty = '0.00'
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
